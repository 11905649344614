<template>
  <div class="footer">
    <ul class="links">
      <li v-for="link in links" class="link" :key="link.tag">
        <a :href="link.route">{{ link.tag }}</a>
      </li>
    </ul>

    <p class="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    links: Array
  }
}
</script>

<style lang='scss'>
.footer {
  margin: 32px 40px 40px;

  & > .links {
    display: flex;

    & > .link {
      margin-right: 10px;
      font-size: 12px;
      letter-spacing: $shorter-letter-spacing;
      line-height: 22px;
      text-transform: uppercase;
      font-weight: 700;
      color: map-get($text-color, base-50);

      & > a:link,
      a:visited {
        color: inherit;
      }
    }
  }

  & > .text {
    font-size: 12px;
    letter-spacing: $shorter-letter-spacing;
    line-height: 22px;
  }
}
</style>
